<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 250px"
        placeholder="请输入手机号筛选"
        v-model="search_str"
        class="input-with-select"
      >
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >通过</el-button
          >
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="edit(scope.row)"
            >编辑权限</el-button
          >
        </template>
      </el-table-column>

      <el-table-column label="清空权限限制">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="clear_quanxian(scope.row)"
            >清空</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="编辑" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="权限设置" name="essentialInformation">
          <el-form :model="formamend">
            <div class="filter">
              <el-input
                style="width: 250px"
                placeholder="请输入对应的筛选"
                v-model="search_company"
                class="input-with-select"
              >
                <el-select
                  v-model="select_index"
                  style="width: 150px"
                  slot="prepend"
                  placeholder="请选择查询方式"
                >
                  <el-option
                    v-for="(item, index) in search_sel"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  @click="search_com"
                  slot="append"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </div>

            <el-table :data="tableData_com_list" style="width: 100%">
              <el-table-column
                v-for="(item, index) in tableData_com"
                :key="index"
                :prop="item.value"
                :label="item.title"
              ></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    class="btn"
                    size="mini"
                    @click="select_redact(scope.row)"
                    >选择</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  search_agent,
  get_agent,
  audit_agent,
  update_quanxian,
} from "@/api/agent/index.js";
import { search_resume } from "@/api/hr24_api/api_all.js";

import { showCity } from "@/api/resume";
import axios from "axios";
export default {
  data() {
    return {
      //总页码
      pageTotal: null,
      pageNow: 1,

      search_company: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_com: [
        { title: "帐号", value: "username" },
        { title: "公司名称", value: "license_company_name" },
        { title: "公司所在地", value: "place_data_str" },
      ],
      elite_id: "",
      tableData_com_list: [],
      search_str: "",

      // 表单数据处理
      tableData_cell: [
        { title: "姓名", value: "truename" },
        { title: "电话", value: "mobile" },
        { title: "微信号", value: "weixin" },
        { title: "分享权限", value: "company_name" },
        { title: "是否是经纪人", value: "status" },
        { title: "申请时间", value: "create_time" },
        { title: "通过时间", value: "update_time" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        username: "",
        contact_name: "",
        license_company_name: [],
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
    };
  },
  mounted() {
    axios.get("/static/industry.json").then((res) => {
      this.indry = res;
    });
    showCity({}).then((res) => {
      console.log(res);
      this.city = res.data;
    });
  },
  methods: {
    //清空限制
    clear_quanxian(e) {
      console.log(e.elite_id);
      //删除
      this.$confirm("是否清空限制？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          update_quanxian({
            elite_id: e.elite_id,
            user_detail_id: "",
          }).then((res) => {
            if (!res.code) {
              this.$message.success(res.msg);
              this.search(1);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //确定选择
    select_redact(e) {
      console.log("select_redact  ");
      console.log(e.user_detail_id, this.elite_id);

      update_quanxian({
        elite_id: this.elite_id,
        user_detail_id: e.user_detail_id,
      }).then((res) => {
        this.search(1);
        if (!res.code) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });

      this.dialogFormVisible = false;
    },
    //查询公司信息
    search_com(e) {
      console.log("dddddddddd search");

      if (!this.search_company || !this.select_index) {
        this.$message.error("请输入帐号查证");
        return;
      }

      if (this.select_index && this.search_company) {
        let search_data = {
          [this.select_index]: this.search_company,
        };
        search_resume({ search_data }).then((res) => {
          if (!res.code) {
            this.tableData_com_list = res.data;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 编辑分享权限
    edit(e) {
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.elite_id = e.elite_id;

      console.log("编辑分享权限", e.elite_id);
    },

    pageNowHandler(page) {
      console.log("pageNowHandler", page);

      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;

        this.search(1);
      }
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {},

    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      console.log(this.search_str, "123345");
      let search_data = {
        mobile: "",
        page: this.pageNow,
      };

      if (this.search_str) {
        search_data = {
          mobile: this.search_str,
          page: this.pageNow,
        };
      }
      search_agent({ search_data }).then((res) => {
        if (!res.code) {
          if (res.data.length) {
            res.data.map((item) => {
              console.log(item);
              item.status = item.status == 1 ? "是" : "否";
            });
          }
          this.pageTotal = res.all_count;
          this.tableData = res.data;
          // this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg);
          console.log("ssssssssss");
        }
        console.log(res);
      });
    },

    redact(e) {
      //通过审核
      let search_data = {
        mobile: e.mobile,
      };

      audit_agent({ search_data }).then((res) => {
        this.$message.success(res.msg);
        this.search(1);
      });
    },

    amend() {
      console.log("dddddddddd");
      console.log(this.is_seed);

      if (this.activeName == "essentialInformation") {
        this.formamend;
        this.formamend.city_id = this.place_data[0];
        this.formamend.district_id = this.place_data[1];
        this.formamend.industry_id = this.industry_name[1];

        let update_data = JSON.stringify(this.formamend);

        let obj = {
          user_detail_id: this.user_detail_id,
          update_data,
        };
        amend_user(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },
  },

  created() {
    this.pageNow = 1;
    this.search(1);
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}

.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
